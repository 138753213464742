import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

    const LanguageSelector = () => {
        const { i18n } = useTranslation();
        const [isOpen, setIsOpen] = useState(false);
      
        const changeLanguage = (lng) => {
          i18n.changeLanguage(lng);
          setIsOpen(false);
        };
      
        const toggleDropdown = () => {
          setIsOpen(!isOpen);
        };
      
        return (
          <div className="relative inline-block text-left">
            <div>
              <button
                type="button"
                onClick={toggleDropdown}
                className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                aria-expanded={isOpen ? 'true' : 'false'}
                aria-haspopup="true"
              >
                Language
                <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fillRule="evenodd" d="M10 12l-8-8h16l-8 8z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
      
            {isOpen && (
              <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <div className="py-1" role="none">
                  <button onClick={() => changeLanguage('en')} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">English</button>
                  <button onClick={() => changeLanguage('id')} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Indonesian</button>
                </div>
              </div>
            )}
          </div>
        );
      };
    const Policy = () => {    
        const { t } = useTranslation();
  return (
    <section className='py-5 px-5'>
        <div className='flex justify-center mt-16'>
        <h1 className='archivo-black-regular md:text-4xl text-2xl font-bold text-blue-500'>{t('PrivacyPolicy.header')}</h1>
      </div>
      <div className='flex justify-between items-center ml-3 mr-3 md:ml-60 md:mr-60 font-lato text-lg'>
        <div></div> 
        <div>
          <LanguageSelector />
        </div>
      </div>

      {/* BayarA's Privacy Policy */}

      <div className='text-left ml-3 mr-3 md:ml-60 md:mr-60 font-lato text-lg'>
        <p className='m-2'>{t('PrivacyPolicy.p1')}</p>
        <p className='m-2'>{t('PrivacyPolicy.p2')}</p>
        <p className='m-2'>{t('PrivacyPolicy.p3')}</p>
        <p className='m-2'>{t('PrivacyPolicy.p4')}</p>
        <p className='m-2'>{t('PrivacyPolicy.p5')}</p>
        <p className='m-2'>{t('PrivacyPolicy.p6')}</p>
        <p className='m-2'>{t('PrivacyPolicy.p7')}</p>
        <p className='m-2'>{t('PrivacyPolicy.p8')}</p>
        <p className='m-2'>{t('PrivacyPolicy.p9')}</p>
        <p className='m-2'>{t('PrivacyPolicy.p10')}</p>
        <p className='m-2'>{t('PrivacyPolicy.p11')}</p>
        <p className='m-2'>{t('PrivacyPolicy.p12')}</p>
        <p className='m-2'>{t('PrivacyPolicy.p13')}</p>
        <p className='m-2'>{t('PrivacyPolicy.p14')}</p>
        <p className='m-2'>{t('PrivacyPolicy.p15')}</p>
        <p className='m-2'>{t('PrivacyPolicy.p16')}</p>
        <p className='m-2'>{t('PrivacyPolicy.p17')}</p>
      </div>

      {/* Privacy Statement And Personal Information Which We Collect */}

    <div className='text-center mt-16'>
        <h1 className='archivo-black-regular md:text-4xl text-2xl font-bold text-blue-500'>{t('Statement.header')}</h1>
        <span className='archivo-black-regular md:text-4xl text-2xl font-bold text-blue-500'>{t('Statement.span1')}</span>
    </div>
    <div className='text-left ml-3 mr-3 md:ml-60 md:mr-60 font-lato text-lg mt-12'>
        <p className='m-2'>{t('Statement.p1')}</p>
        <p className='m-2 text-black font-bold'>{t('Statement.head1')}</p>
        <p className='m-2'>{t('Statement.p2')}</p>
        <p className='m-2 text-black font-bold'>{t('Statement.head2')}</p>
        <p className='m-2'>{t('Statement.p3')}</p>
        <p className='m-2'>{t('Statement.p4')}</p>
        <p className='m-2'>{t('Statement.p5')}</p>
        <p className='m-2'>{t('Statement.p6')}</p>
        <p className='m-2'>{t('Statement.p7')}</p>
        <p className='m-2 text-black font-bold'>{t('Statement.head3')}</p>
        <p className='m-2'>{t('Statement.p8')}</p>
        <p className='m-2 text-black font-bold'>{t('Statement.head4')}</p>
        <p className='m-2'>{t('Statement.p9')}</p>
    </div>

    {/* The Use Of Personal Information Which We Collect*/}

    <div className='text-center mt-16'>
        <h1 className='archivo-black-regular md:text-4xl text-2xl font-bold text-blue-500'>{t('Collect.header')}</h1>
        <span className='archivo-black-regular md:text-4xl text-2xl font-bold text-blue-500'>{t('Collect.span1')}</span>
    </div>
    <div className='text-left ml-3 mr-3 md:ml-60 md:mr-60 font-lato text-lg mt-12'>
        <p className='m-2'>{t('Collect.p1')}</p>
        <p className='m-2'>{t('Collect.p2')}</p>
        <p className='m-2'>{t('Collect.p3')}</p>
        <p className='m-2'>{t('Collect.p4')}</p>
        <p className='m-2'>{t('Collect.p5')}</p>
        <p className='m-2'>{t('Collect.p6')}</p>
        <p className='m-2'>{t('Collect.p7')}</p>
        <p className='m-2'>{t('Collect.p8')}</p>
        <p className='m-2'>{t('Collect.p9')}</p>
        <p className='m-2'>{t('Collect.p10')}</p>
        <p className='m-2'>{t('Collect.p11')}</p>
        <p className='m-2'>{t('Collect.p12')}</p>
        <p className='m-2'>{t('Collect.p13')}</p>
        <p className='m-2'>{t('Collect.p14')}</p>
        <p className='m-2'>{t('Collect.p15')}</p>
        <p className='m-2'>{t('Collect.p16')}</p>
        <p className='m-2'>{t('Collect.p17')}</p>
        <p className='m-2'>{t('Collect.p18')}</p>
        <p className='m-2'>{t('Collect.p19')}</p>
      </div>

      {/* Sharing Of Personal Data Information Which We Collect */}

    <div className='text-center mt-16'>
        <h1 className='archivo-black-regular md:text-4xl text-2xl font-bold text-blue-500'>{t('Sharing.header')}</h1>
        <span className='archivo-black-regular md:text-4xl text-2xl font-bold text-blue-500'>{t('Sharing.span1')}</span>
    </div>
    <div className='text-left ml-3 mr-3 md:ml-60 md:mr-60 font-lato text-lg mt-12'>
        <p className='m-2'>{t('Sharing.p1')}</p>
        <p className='m-2'>{t('Sharing.p2')}</p>
        <p className='m-2'>{t('Sharing.p3')}</p>
        <p className='m-2'>{t('Sharing.p4')}</p>
        <p className='m-2'>{t('Sharing.p5')}</p>
        <p className='m-2'>{t('Sharing.p6')}</p>
        <p className='m-2'>{t('Sharing.p7')}</p>
        <p className='m-2'>{t('Sharing.p8')}</p>
        <p className='m-2'>{t('Sharing.p9')}</p>
        <p className='m-2'>{t('Sharing.p10')}</p>
        <p className='m-2'>{t('Sharing.p11')}</p>
        <p className='m-2'>{t('Sharing.p12')}</p>
        <p className='m-2'>{t('Sharing.p13')}</p>
      </div>

    {/* Retention Of Personal Information */}
    
    <div className='flex justify-center mt-16'>
        <h1 className='archivo-black-regular md:text-4xl text-2xl font-bold text-blue-500'>{t('Retention.header')}</h1>
    </div>
    <div className='text-left ml-3 mr-3 md:ml-60 md:mr-60 font-lato text-lg mt-12'>
        <p className='m-2'>{t('Retention.p1')}</p>
        <p className='m-2'>{t('Retention.p2')}</p>   
    </div>

    {/* Access And Correction Of Personal Information */}

    <div className='flex justify-center mt-16'>
        <h1 className='archivo-black-regular md:text-4xl text-2xl font-bold text-blue-500'>{t('Correction.header')}</h1>
    </div>
    <div className='text-left ml-3 mr-3 md:ml-60 md:mr-60 font-lato text-lg mt-12'>
        <p className='m-2'>{t('Correction.p1')}</p>
        <p className='m-2'>{t('Correction.p2')}</p>   
    </div>

    {/* Cross Border Data Transfer */}

    <div className='flex justify-center mt-16'>
        <h1 className='archivo-black-regular md:text-4xl text-2xl font-bold text-blue-500'>{t('Cross.header')}</h1>
    </div>
    <div className='text-left ml-3 mr-3 md:ml-60 md:mr-60 font-lato text-lg mt-12'>
        <p className='m-2'>{t('Cross.p1')}</p>
        <p className='m-2'>{t('Cross.p2')}</p>   
    </div>

    {/* Security Of Personal Information */}

    <div className='flex justify-center mt-16'>
        <h1 className='archivo-black-regular md:text-4xl text-2xl font-bold text-blue-500'>{t('Security.header')}</h1>
    </div>
    <div className='text-left ml-3 mr-3 md:ml-60 md:mr-60 font-lato text-lg mt-12'>
        <p className='m-2'>{t('Security.p1')}</p> 
    </div>

    {/* Changes To This Privacy Policy */}

    <div className='flex justify-center mt-16'>
        <h1 className='archivo-black-regular md:text-4xl text-2xl font-bold text-blue-500'>{t('Changes.header')}</h1>
    </div>
    <div className='text-left ml-3 mr-3 md:ml-60 md:mr-60 font-lato text-lg mt-12'>
        <p className='m-2'>{t('Changes.p1')}</p> 
    </div>

    {/* Acknowledgement And Consent */}
   
    <div className='flex justify-center mt-16'>
        <h1 className='archivo-black-regular md:text-4xl text-2xl font-bold text-blue-500'>{t('Acknowledgement.header')}</h1>
    </div>
    <div className='text-left ml-3 mr-3 md:ml-60 md:mr-60 font-lato text-lg mt-12'>
        <p className='m-2'>{t('Acknowledgement.p1')}</p>
        <p className='m-2'>{t('Acknowledgement.p2')}</p>
        <p className='m-2'>{t('Acknowledgement.p3')}</p> 
    </div>

    {/* Marketing And Promotional Material */}

    <div className='flex justify-center mt-16'>
        <h1 className='archivo-black-regular md:text-4xl text-2xl font-bold text-blue-500'>{t('Acknowledgement.header')}</h1>
    </div>
    <div className='text-left ml-3 mr-3 md:ml-60 md:mr-60 font-lato text-lg mt-12'>
        <p className='m-2'>{t('Acknowledgement.p1')}</p> 
    </div>

    {/* Anonymous Data */}

    <div className='flex justify-center mt-16'>
        <h1 className='archivo-black-regular md:text-4xl text-2xl font-bold text-blue-500'>{t('Anonymous.header')}</h1>
    </div>
    <div className='text-left ml-3 mr-3 md:ml-60 md:mr-60 font-lato text-lg mt-12'>
        <p className='m-2'>{t('Anonymous.p1')}</p> 
    </div>

    {/* Third Party Platforms */}

    <div className='flex justify-center mt-16'>
        <h1 className='archivo-black-regular md:text-4xl text-2xl font-bold text-blue-500'>{t('Third.header')}</h1>
    </div>
    <div className='text-left ml-3 mr-3 md:ml-60 md:mr-60 font-lato text-lg mt-12'>
        <p className='m-2'>{t('Third.p1')}</p> 
        <p className='m-2'>{t('Third.p2')}</p> 
    </div>

    {/* Severability */}

    <div className='flex justify-center mt-16'>
        <h1 className='archivo-black-regular md:text-4xl text-2xl font-bold text-blue-500'>{t('Severability.header')}</h1>
    </div>
    <div className='text-left ml-3 mr-3 md:ml-60 md:mr-60 font-lato text-lg mt-12'>
        <p className='m-2'>{t('Severability.p1')}</p> 
    </div>

    {/* Contact Us */}

    <div className='flex justify-center mt-16'>
        <h1 className='archivo-black-regular md:text-4xl text-2xl font-bold text-blue-500'>{t('Contact.header')}</h1>
    </div>
    <div className='text-left ml-3 mr-3 md:ml-60 md:mr-60 font-lato text-lg mt-12'>
        <p className='m-2'>{t('Contact.p1')}</p> 
    </div>
    </section>
  )
}
export default Policy

