import React from 'react';
import bgprice from '../images/bgprice.jpg';

const Pricing = () => {
  const containerStyle = {
    position: 'relative',
    zIndex: 0,
    width: '100%',
    minHeight: '100vh',
  };

  const backgroundStyle = {
    position: 'absolute',
    zIndex: -1,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${bgprice})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    filter: 'brightness(55%)',
  };

  const boxes = [
    {
      id: 1,
      color: 'white',
      content: (
        <div className='justify-center text-center items-center m-3'>
          <h2 className="archivo-regular md:text-xl text-base mt-2">REGULAR</h2>
          <p className='archivo-regular font-bold md:text-xl text-base mt-3'>Rp79.999</p>
          <span className='archivo-regular text-sm m-1'>Per Bulan</span>
          <hr className="border-t border-b border-gray-500 mt-2"></hr>
          <p className='archivo-regular font-bold md:text-xl text-base m-4'>Memulai bisnis Anda dengan fitur yang sesuai kebutuhan dasar.</p>
          <button className='bg-teal-600 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded-full shadow-md transition duration-300 ease-in-out m-3'>Coba Demo</button>      
        </div>
      )
    },
    {
      id: 2,
      color: 'white',
      content: (
        <div className='justify-center text-center items-center m-3'>
          <h2 className="archivo-regular md:text-xl text-base mt-2">BASIC</h2>
          <p className='archivo-regular font-bold md:text-xl text-base mt-3'>Rp229.999</p>
          <span className='archivo-regular text-sm m-1'>Per Bulan</span>
          <hr className="border-t border-b border-gray-500 mt-2"></hr>
          <p className='archivo-regular font-bold md:text-xl text-base m-4'>Fitur premium untuk pertumbuhan bisnis yang lebih cepat dan efisien.</p>
          <button className='bg-teal-600 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded-full shadow-md transition duration-300 ease-in-out m-3'>Coba Demo</button>      
        </div>
      )
    },
    {
      id: 3,
      color: 'white',
      content: (
        <div className='justify-center text-center items-center m-3'>
          <h2 className="archivo-regular md:text-xl text-base mt-2">PRO</h2>
          <p className='archivo-regular font-bold md:text-xl text-base mt-3'>Rp429.999</p>
          <span className='archivo-regular text-sm m-1'>Per Bulan</span>
          <hr className="border-t border-b border-gray-500 mt-2"></hr>
          <p className='archivo-regular font-bold md:text-xl text-base m-4'>Menyediakan Anda dengan dukungan 24/7 dan fitur-fitur kelas atas.</p>
          <button className='bg-teal-600 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded-full shadow-md transition duration-300 ease-in-out m-3'>Coba Demo</button>      
        </div>
      )
    },
    {
      id: 4,
      color: 'white',
      content: (
        <div className='justify-center text-center items-center m-3'>
          <h2 className="archivo-regular md:text-xl text-base mt-2">Ultimate</h2>
          <p className='archivo-regular font-bold md:text-xl text-base mt-3'>Rp629.999</p>
          <span className='archivo-regular text-sm m-1'>Per Bulan</span>
          <hr className="border-t border-b border-gray-500 mt-2"></hr>
          <p className='archivo-regular font-bold md:text-xl text-base m-4'>Kustomisasi eksklusif untuk kebutuhan bisnis tertinggi.</p>
          <button className='bg-teal-600 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded-full shadow-md transition duration-300 ease-in-out m-3'>Coba Demo</button>      
        </div>
      )
    },
  ];

  return (
    <section id='pricing' style={containerStyle}>
      <div style={backgroundStyle}></div>
      <div className='text-center justify-center items-center'>
        <h1 className='archivo-black-regular text-white text-3xl  md:text-5xl mb-20'>OUR PRICING</h1>
      </div>
      <div className='flex flex-wrap justify-center text-center items-center md:items-start md:ml-0'>
        {boxes.map(box => (
          <div key={box.id} className={`w-full md:w-72 h-auto md:h-500 bg-${box.color} border border-gray-300 m-5 p-4 rounded-lg shadow-lg md:flex flex-col text-center justify-center items-center`}>
            {box.content}
          </div>
        ))}
      </div>
    </section>
  );
}

export default Pricing;
