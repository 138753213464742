import React, { useState } from 'react';
import BAYARA from '../images/BAYARA.png';
import { Link } from 'react-router-dom';

 const NavTerm = () => {
    const [open, setOpen] = useState(false);

    const toggleMenu = () => {
        setOpen(!open);
    };

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

  return (
    <section>
        <div className={`p-2 w-full fixed top-0 left-0 transition duration-500 ease-in  bg-gray-900`} style={{ zIndex: '1000' }}>
            <div className="md:flex py-4 items-center justify-between md:px-10 px-7">
                <div className="cursor-pointer flex items-center">
                    <Link to='/' onClick={scrollTop}>
                        <img src={BAYARA} alt="LogoBayarA" className="h-8 w-auto ml-6" />
                    </Link>
                </div>
                <div onClick={toggleMenu} className="text-3xl absolute right-8 top-6 cursor-pointer md:hidden text-gray-400">
                    <ion-icon name={open ? 'close' : 'menu'}></ion-icon>
                </div>
                <ul className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition duration-500 ease-in ${open ? 'top-20 opacity-100' : 'top-[-490px]'} md:opacity-100 opacity-0 md:bg-transparent bg-gray-500`}>
                    <li className="md:ml-6 text-sm font-bold md:my-0 my-7 font-montserrat text-white md:text-white hover:text-gray-500">
                    <Link to='/' onClick={scrollTop}>HOME</Link>
                    </li>
                    <li className="md:ml-6 text-sm font-bold md:my-0 my-7 font-montserrat text-white md:text-white hover:text-gray-500">
                    <a href='https://play.google.com/store/apps?hl=id&gl=US'>DOWNLOAD</a>
                    </li>
                    <li className="md:ml-6 text-sm font-bold md:my-0 my-7 font-montserrat text-white md:text-white hover:text-gray-500">
                    <button className='bg-teal-600 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded-full shadow-md transition duration-300 ease-in-out'>DEMO</button>
                    </li>
                </ul>
            </div>
        </div>    
    </section>
  )
}
export default NavTerm;