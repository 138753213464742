import React from 'react'
import { useTranslation } from 'react-i18next'

const Miscellaneous = () => {
  const { t } = useTranslation();
  return (
    <section className='py-5 px-5'>
    <div className='justify-center text-center items-center mt-3 mb-8'>
        <h1 className='archivo-black-regular md:text-4xl text-2xl font-bold text-blue-500'>{t('Mis.header')}</h1>
    </div>
    <div className='text-left ml-3 mr-3 md:ml-60 md:mr-60 font-lato text-lg mb-60'>
        <p className='m-2'>{t('Mis.p1')}</p>
        <p className='m-2'>{t('Mis.p2')}</p>
        <p className='m-2'>{t('Mis.p3')}</p>
        <p className='m-2'>{t('Mis.p4')}</p>
        <p className='m-2'>{t('Mis.p5')}</p>      
    </div>
    </section>
  )
}
export default Miscellaneous
