import React, { useEffect } from 'react';
import Navbar from './Navbar';
import gambar1 from '../images/gambar1.png'
import gambar2 from '../images/gambar2.png'
import gambar3 from '../images/gambar3.png'

const MarketingPage = () => {
  useEffect(() => {
    const sections = document.querySelectorAll('.animate');
    
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animated');
        } else {
          entry.target.classList.remove('animated');
        }
      });
    });
    
    sections.forEach(section => {
      observer.observe(section);
    });

    return () => {
      sections.forEach(section => {
        observer.unobserve(section);
      });
    };
  }, []);

  return (
    <>
      <Navbar />
      <section id='Marketing1'>
  <div className='mt-15 text-center justify-centers px-20 py-20'>
    <div className='items-center text-center justify-center md:text-right md:justify-end mb-20 animate'>
      <div className="md:flex md:justify-between md:text-right md:items-center">
        <div className="md:w-1/2">
          <img src={gambar3} className='marketingimage3 w-full md:w-auto' alt='Gambar Marketing 3' />
        </div>
        <div className='md:text-right md:ml-6 md:w-1/2'>
          <h1 className='font-bold text-gray-800 font-montserrat text-4xl md:text-5xl mt-20 mb-5'>Kelola Stok dengan Mudah</h1>
          <p className='text-gray-400 font-montserrat text-3sm md:text-5sm md:max-w-screen-md md:ml-auto mb-20'>Tidak perlu lagi khawatir tentang stok habis atau berlebih. Aplikasi kasir kami memberikan kemudahan dalam mengatur dan memantau stok produk Anda secara real-time, sehingga Anda dapat membuat keputusan yang tepat dalam pengadaan barang.</p>
        </div>
      </div>
    </div>
    <div className='items-center justify-center md:justify-start mt-20 mb-20 animate'>
      <div className='md:flex md:justify-between md:text-right md:items-center'>
        <div className="md:w-1/2 md:text-left md:mr-10">
          <h1 className='font-bold text-gray-800 font-montserrat text-4xl md:text-5xl'>Rekam Transaksi Secara Efisien</h1>
          <p className='text-gray-400 font-montserrat text-3sm md:text-5sm mt-5 md:max-w-screen-md'>Pencatatan transaksi menjadi lebih mudah dengan aplikasi kasir kami. Dengan beberapa klik saja, Anda dapat mencatat setiap transaksi dengan detail yang lengkap, termasuk informasi produk, jumlah, dan metode pembayaran.</p>
        </div>
        <div className="md:w-1/2">
          <img src={gambar2} className='marketingimage2 w-full md:w-auto md:mt-0 md:mb-0 mt-3px mb-3px' alt="Gambar Marketing" />
        </div>
      </div>
    </div>
    <div className="items-center text-center justify-center md:text-right md:justify-end mb-20 mt-20 animate">
      <div className="md:flex md:justify-between md:text-right md:items-center">
        <div className="md:w-1/2">
          <img src={gambar1} className="marketingimage w-full md:w-auto md:mb-0 md:mt-0 mt-3px mb-3px" alt="Gambar Marketing" />
        </div>
        <div className="md:text-right md:ml-6 md:w-1/2">
          <h1 className="font-bold text-gray-800 font-montserrat text-4xl md:text-5xl">Sederhana dan Intuitif</h1>
          <p className="text-gray-400 font-montserrat text-3sm md:text-5sm mt-5 md:max-w-screen-md">
            Kami memahami bahwa waktu Anda berharga, itulah mengapa kami merancang aplikasi kasir kami agar mudah dipahami dan digunakan oleh siapa pun. Dengan antarmuka yang intuitif, Anda dapat dengan cepat menguasai fitur-fitur kami dan fokus pada pertumbuhan bisnis Anda.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  );
};

export default MarketingPage
