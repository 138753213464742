import React, { useState, useEffect } from 'react';
import BAYARA from '../images/BAYARA.png';
import Mockup1 from '../images/Mockup1.png';
import Mockup2 from '../images/Mockup2.jpg';

const HomeSection = () => {
  const [currentBackground, setCurrentBackground] = useState(Mockup1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentBackground(prevBackground =>
        prevBackground === Mockup1 ? Mockup2 : Mockup1
      );
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);


  const sectionStyle = {
    position: 'relative', 
    zIndex: 0,
  };
  
  const backgroundStyle = {
    ...sectionStyle,
    backgroundImage: `url(${currentBackground})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    transition: 'background-image 1.5s ease-in-out',
    filter: 'brightness(50%)',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    position: 'absolute', 
    zIndex: -1, 
  };
  return (

      <section id="HomeSection" style={sectionStyle} className="px-4 md:px-10 lg:px-20 py-20 md:py-32 flex items-center h-screen text-center lg:text-left justify-center lg:justify-start">
        <div style={backgroundStyle}></div>
        <div className="container mx-auto">
          <div className="mx-auto">
            <div className="flex items-center justify-center text-center font-montserrat md:px-20 md:ml-0 lg:ml-20">
              <h1 className="text-xl md:text-3xl text-blue-200 font-bold">LENGKAP DAN TERJANGKAU</h1>
            </div>
            <div className="flex items-center justify-center text-center font-montserrat mt-3 md:px-20 md:ml-0 lg:ml-20">
              <img src={BAYARA} alt="LogoBayarA" className="gambarbayara sm:h-13 w-auto" />
            </div>
            <div className='flex justify-center items-center text-center md:px-20 md:ml-0 lg:ml-20'>
              <p className='text-sm font-bold text-white mt-3 mb-5 md:mb-3 md:px-8 md:max-w-2xl'>Menghadirkan solusi kasir yang tak tertandingi, BayarA memberikan Anda keunggulan kompetitif yang dibutuhkan untuk meraih kesuksesan dalam pasar yang kompetitif saat ini. Dengan antarmuka yang intuitif dan fitur-fitur canggih.</p>
            </div>
            <div className="items-center text-center justify-center mt-5 md:px-20 ml-0 md:ml-20">
              <a href="/" className="inline-block bg-teal-600 hover:bg-gray-400 duration-500 text-white font-bold py-2 px-6 rounded-full m-3 md:m-5 lg:m-6">Jadwalkan Demo</a>
              <a href="/" className="inline-block bg-gray-300 hover:bg-gray-400 text-gray-700 font-bold py-2 px-6 rounded-full m-3 md:m-5 lg:m-6">Kontak Kami</a>
            </div>
          </div>
        </div>
      </section>
  );
};

export default HomeSection;
