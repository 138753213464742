import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TermPage from './TermPage';
import LandingPage from './LandingPage'; 
import HomeSection from '../layouts/HomeSection';
import PrivacyPage from './PrivacyPage';


const App = () => {

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/terms" element={<TermPage />} />
        <Route exact path='/home' element={<HomeSection />} />
        <Route exact path='/privacy' element={<PrivacyPage />} />
      </Routes>
    </Router>
  );
};

export default App;
