import React from 'react';
import Navbar from '../layouts/Navbar';
import '../index.css';
import HomeSection from '../layouts/HomeSection';
import MarketingPage from '../layouts/MarketingPage';
import Footer from '../layouts/Footer'
import Pricing  from '../layouts/Pricing';
import Contact from '../layouts/Contact';
import Features from '../layouts/Features';

function LandingPage() {
  return (
    <div className='bg-white relative'>
      <Navbar />
      <HomeSection />
      
      <MarketingPage />

      <Pricing />
      <Features />

      <Contact />

      <Footer />
      

    </div>
  );
}


export default LandingPage;