import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './pages/App';
import {  initReactI18next } from "react-i18next";
import i18n from 'i18next';
import enTranslation from './Translation/en.json';
import idTranslation from './Translation/id.json';
import reportWebVitals from './reportWebVitals';

i18n.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: "id",
  resources: {
    en: {
      translation: enTranslation,
    },
    id: {
      translation: idTranslation,
    },
  },
});

const root = createRoot(document.getElementById("root"));
root.render(<App />);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
