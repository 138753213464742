import React from 'react';
import NavTerm from '../layouts/NavTerm'
import Footer from '../layouts/Footer'
import gambarprivacy from '../images/gambarprivacy.jpg'
import Policy from '../components/PrivacyComponents/Policy';

const PrivacyPage = () => {
  const containerStyle = {
    position: 'relative',
    zIndex: 0,
    width: '100%',
    minHeight: '100vh', 
  };

  const backgroundStyle = {
    position: 'absolute',
    zIndex: -1,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${gambarprivacy})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    filter: 'brightness(45%)', 
  };

  const headingContainerStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    width: '100%',
  };

  const headingStyle = {
    color: '#fff',
    fontSize: '3rem', // Ukuran font
  };

  return (
    <>
      <NavTerm /> 
      <section id='privacy' style={containerStyle}>
        <div style={backgroundStyle}></div>
        <div style={headingContainerStyle}>
          <h1 style={headingStyle} className='archivo-black-regular font-bold'>PRIVACY POLICY</h1>
        </div>
      </section>
      <Policy />
      
      <Footer />
    </>
  )
};

export default PrivacyPage;
