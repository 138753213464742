import React from 'react'
import gambarcontact from '../images/gambarcontact.jpg'

const Contact = () => {
  const containerStyle = {
    position: 'relative',
    zIndex: 0,
    width: '100%',
    minHeight: '100vh', 
  };

  const backgroundStyle = {
    position: 'absolute',
    zIndex: -1,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${gambarcontact})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    filter: 'brightness(50%)', 
  };
  return (
    <section id='contact' style={containerStyle}>
      <div style={backgroundStyle}></div>
        <div className='justify-center text-center items-center mt-2 mb-20'>
            <h1 className='archivo-black-regular font-bold text-gray-300 md:text-3xl text-xl'>CONTACT US</h1>
        </div>
        <div className='md:justify-start md:items-start md:text-left md:ml-60 justify-center items-center text-center'>
          <h1 className='archivo-black-regular font-bold text-blue-400 md:text-4xl text-base mb-10 md:mb-40'>Hi! How We Can Help?</h1>
          <a href="https://wa.me/+6282280000616">
          <button className='bg-teal-600 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded-full shadow-md transition duration-300 ease-in-out mt-3'>WhatsApp Now!</button></a>
      </div>
    </section>
    
  )
}
export default Contact;
