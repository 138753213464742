import React from "react";

export default function Features() {
  return (
    <section id="features">
      <div className="justify-center text-center items-center mt-10">
        <h1 className="text-black md:text-5xl text-xl m-5 mb-20 font-bold">FEATURES</h1>
      </div>
      <div className="py-5 md:flex md:justify-center overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="bg-gray-100 border-b border-gray-200">
              <th className="px-4 py-2"></th>
              <th className="md:px-4 px-2 py-2">
                <strong className="archivo-regular md:text-base text-td">Regular</strong>
              </th>
              <th className="md:px-4 px-2 py-2">
                <strong className="archivo-regular md:text-base text-td">Basic</strong>
              </th>
              <th className="md:px-4 px-2 py-2">
                <strong className="archivo-regular md:text-base text-td ">Pro</strong>
              </th>
              <th className="md:px-4 px-2 py-2">
                <strong className="archivo-regular md:text-base text-td">Ultimate</strong>
              </th>
            </tr>
          </thead>
        <tbody>
 {/* 1 */}
 <tr className="border-b border-gray-200">
              <td className="px-4 py-2 font-bold md:text-base text-td archivo-regular">
                Fitur POS <br className="md:hidden"/> Dasar
              </td>
              <td className="px-4 py-2">
                <span className="text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                  </svg>
                </span>
              </td>
              <td className="px-4 py-2">
                <span className="text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                  </svg>
                </span>
              </td>
              <td className="px-4 py-2">
                <span className="text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                  </svg>
                </span>
              </td>
              <td className="px-4 py-2">
                <span className="text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                  </svg>
                </span>
              </td>
            </tr>
            {/* 2 */}
            <tr className="border-b border-gray-200">
              <td className="px-4 py-2 md:text-base text-td">
                1. Aplikasi{' '}
                <br className="md:hidden"/>Kasir Dasar
                <br className="md:hidden"/> (Dashboard)
              </td>
              <td className="px-4 py-2">
                <span className="text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </span>
              </td>
              <td className="px-4 py-2">
                <span className="text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </span>
              </td>
              <td className="px-4 py-2">
                <span className="text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </span>
              </td>
              <td className="px-4 py-2">
                <span className="text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </span>
              </td>
            </tr>
    {/* 3 */}
    <tr className="border-b border-gray-200">
              <td className="px-4 py-2 md:text-base text-td">2. Maks 
                <br className="md:hidden"/> Produk
              </td>
              <td className="px-4 py-2">
                <span className="text-black md:text-base text-td">
                  10
                  <br className="md:hidden"/> Produk
                </span>
              </td>
              <td className="px-4 py-2">
                <span className="text-black md:text-base text-td">
                  30
                  <br className="md:hidden"/> Produk
                </span>
              </td>
              <td className="px-4 py-2">
                <span className="text-black md:text-base text-td">
                  100
                  <br className="md:hidden"/> Produk
                </span>
              </td>
              <td className="px-4 py-2">
                <span className="text-black md:text-base text-td">
                  Unlimited
                </span>
              </td>
            </tr>
            {/* 4 */}
            <tr className="border-b border-gray-200">
              <td className="px-4 py-2  md:text-base text-td">3. Diskon</td>
              <td className="px-4 py-2">
                <span className="text-red-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </span>
              </td>
              <td className="px-4 py-2">
                <span className="text-red-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </span>
              </td>
              <td className="px-4 py-2">
                <span className="text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </span>
              </td>
              <td className="px-4 py-2">
                <span className="text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </span>
              </td>
            </tr>

    {/* 5 */}
    <tr className="border-b border-gray-200">
              <td className="px-4 py-2  md:text-base text-td">4. Pajak PB1</td>
              <td className="px-4 py-2">
                <span className="text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </span>
              </td>
              <td className="px-4 py-2">
                <span className="text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </span>
              </td>
              <td className="px-4 py-2">
                <span className="text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </span>
              </td>
              <td className="px-4 py-2">
                <span className="text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </span>
              </td>
            </tr>
            {/* 6 */}
            <tr className="border-b border-gray-200">
              <td className="px-4 py-2  md:text-base text-td">5. Pegawai 
                <br className="md:hidden"/> (Maks)     
              </td>
              <td className="px-4 py-2">
                <span className="text-black md:text-base text-td">
                  1 
                  <br className="md:hidden"/> Pegawai
                </span>
              </td>
              <td className="px-4 py-2">
                <span className="text-black md:text-base text-td">
                  5 
                  <br className="md:hidden"/> Pegawai
                </span>
              </td>
              <td className="px-4 py-2">
                <span className="text-black md:text-base text-td">
                  10
                  <br className="md:hidden"/> Pegawai
                </span>
              </td>
              <td className="px-4 py-2  md:text-base text-td">
                <span className="text-black">
                  20
                  <br className="md:hidden"/> Pegawai
                </span>
              </td>
            </tr>
    {/* 7 */}
    <tr className="border-b border-gray-200">
              <td className="px-4 py-2 md:text-base text-td">
                Tambahan
                <br className="md:hidden"/> Pegawai
                <br className="md:hidden"/> Rp15.000
                <br className="md:hidden"/> per
                <br className="md:hidden"/> Bulan
              </td>
              <td className="px-4 py-2"></td>
              <td className="px-4 py-2"></td>
              <td className="px-4 py-2"></td>
              <td className="px-4 py-2"></td>
            </tr>
            <tr className="border-b border-gray-200">
              <td className="px-4 py-2"></td>
              <td className="px-4 py-2"></td>
              <td className="px-4 py-2"></td>
              <td className="px-4 py-2"></td>
              <td className="px-4 py-2"></td>
            </tr>
    {/* 8 */}
            <tr className="border-b border-gray-200">
              <td className="px-4 py-2 font-bold md:text-base text-td archivo-regular">Laporan</td>
              <td className="px-4 py-2"></td>
              <td className="px-4 py-2"></td>
              <td className="px-4 py-2"></td>
              <td className="px-4 py-2"></td>
            </tr>
    {/* 9 */}
    <tr className="border-b border-gray-200">
              <td className="px-4 py-2 md:text-base text-td">1. Laporan Penjualan</td>
              <td className="px-4 py-2">
                <span className="text-black  md:text-base text-td">
                  30 Hari Terakhir
                </span>
              </td>
              <td className="px-4 py-2  md:text-base text-td">
                <span className="text-black">
                  Tidak Terbatas
                </span>
              </td>
              <td className="px-4 py-2  md:text-base text-td">
                <span className="text-black">
                  Tidak Terbatas
                </span>
              </td>
              <td className="px-4 py-2  md:text-base text-td">
                <span className="text-black">
                  Tidak Terbatas
                </span>
              </td>
            </tr>
            {/* 11 */}
            <tr className="border-b border-gray-200  md:text-base text-td">
              <td className="px-4 py-2">2. Management Stok</td>
              <td className="px-4 py-2">
                <span className="text-red-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </span>
              </td>
              <td className="px-4 py-2">
                <span className="text-red-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </span>
              </td>
              <td className="px-4 py-2">
                <span className="text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </span>
              </td>
              <td className="px-4 py-2">
                <span className="text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </span>
              </td>
            </tr>
            {/* 10 */}
            <tr className="border-b border-gray-200">
              <td className="px-4 py-2 md:text-base text-td">3. Management Biaya</td>
              <td className="px-4 py-2">
                <span className="text-red-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </span>
              </td>
              <td className="px-4 py-2">
                <span className="text-red-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </span>
              </td>
              <td className="px-4 py-2">
                <span className="text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </span>
              </td>
              <td className="px-4 py-2">
                <span className="text-green-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </span>
              </td>
            </tr>         
          {/* 12 */}
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2 md:text-base text-td">4. Management Cashflow</td>
        <td className="px-4 py-2">
          <span className="text-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
      </tr>
      {/* 13 */}
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2 md:text-base text-td">5. Export Laporan</td>
        <td className="px-4 py-2">
          <span className="text-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
      </tr>
      {/* 14 */}
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2 md:text-base text-td">6. Management Pegawai</td>
        <td className="px-4 py-2">
          <span className="text-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18 L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
      </tr>
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
      </tr>
      {/* 15 */}
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2 font-bold md:text-base text-td archivo-regular">Penerimaan Pembayaran</td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
      </tr>
      {/* 16 */}
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2 md:text-base text-td">1. Pembayaran Digital(Qris)</td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
      </tr>
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
      </tr>
      {/* 17 */}
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2 font-bold md:text-base text-td archivo-regular">Penerimaan Pemesanan</td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
      </tr>
      {/* 18 */}
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2 md:text-base text-td">1. Management Meja</td>
        <td className="px-4 py-2">
          <span className="text-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
      </tr>
           {/* 19 */}
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2 md:text-base text-td">2. Pemesanan Digital Scan QR</td>
        <td className="px-4 py-2">
          <span className="text-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
      </tr>
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
      </tr>
      {/* 20 */}
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2 font-bold md:text-base text-td archivo-regular">Pengaturan BackOfiice</td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
      </tr>
      {/* 21 */}
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2 md:text-base text-td">1. Management Bahan Baku</td>
        <td className="px-4 py-2">
          <span className="text-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
      </tr>
          {/* 23 */}
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2 md:text-base text-td">2. Pemasukan Stok</td>
        <td className="px-4 py-2">
          <span className="text-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
      </tr>
      {/* 24 */}
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2 md:text-base text-td">3. Stok Opname</td>
        <td className="px-4 py-2">
          <span className="text-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
      </tr>
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
      </tr>
          {/* 27 */}
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2 font-bold md:text-base text-td archivo-regular">Management Pelanggan</td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
      </tr>
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2 md:text-base text-td">1. Pengiriman Struk Via Email</td>
        <td className="px-4 py-2">
          <span className="text-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2 md:text-base text-td">
          <span className="text-black">Tidak Terbatas</span>
        </td>
        <td className="px-4 py-2 md:text-base text-td">
          <span className="text-black">Tidak Terbatas</span>
        </td>
        <td className="px-4 py-2 md:text-base text-td">
          <span className="text-black">Tidak Terbatas</span>
        </td>
      </tr>
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2 md:text-base text-td">2. Management Database</td>
        <td className="px-4 py-2">
          <span className="text-black md:text-base text-td">100 Pelanggan</span>
        </td>
        <td className="px-4 py-2">
          <span className="text-black md:text-base text-td">5000 Pelanggan</span>
        </td>
        <td className="px-4 py-2">
          <span className="text-black md:text-base text-td">30000 Pelanggan</span>
        </td>
        <td className="px-4 py-2">
          <span className="text-black md:text-base text-td">Unlimited</span>
        </td>
      </tr>
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
      </tr>
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2 font-bold md:text-base text-td archivo-regular">Menu Cetakan</td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td></td>
      </tr>
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2 md:text-base text-td">1. Struk Pembayaran</td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
      </tr>
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2 md:text-base text-td">2. Menu Pesanan / Bill Check</td>
        <td className="px-4 py-2">
          <span className="text-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
      </tr>
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
      </tr>
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2 font-bold md:text-base text-td archivo-regular">Dukungan Lainnya</td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td className="px-4 py-2"></td>
        <td></td>
      </tr>
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2 md:text-base text-td">1. Edukasi dan Training Online</td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
      </tr>
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2 md:text-base text-td">2. Setup dan Training Tatap Muka</td>
        <td className="px-4 py-2">
          <span className="text-red-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
      </tr>

      <tr className="border-b border-gray-200">
        <td className="px-4 py-2 md:text-base text-td">3. Live Chat dan Whatsapp Support</td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
        <td className="px-4 py-2">
          <span className="text-green-500">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
          </span>
        </td>
      </tr>
      <tr className="border-b border-gray-200">
        <td className="px-4 py-2 md:text-base text-td">4. Pendamping Account Manager</td>
        <td className="px-4 py-2">
          <span className="text-black md:text-base text-td">Minimal 5 Outlet</span>
        </td>
        <td className="px-4 py-2">
          <span className="text-black md:text-base text-td">Minimal 5 Outlet</span>
        </td>
        <td className="px-4 py-2">
          <span className="text-black md:text-base text-td">Minimal 5 Outlet</span>
        </td>
        <td className="px-4 py-2">
          <span className="text-black md:text-base text-td">Tanpa Minimal Outlet</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
</section>
  );
}