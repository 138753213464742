import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PrivacynPolicy from './PrivacynPolicy';
import Amendments from './Amendments';
import AccountInformation from './AccountInformation';
import FeesandTaxes from './FeesandTaxes';
import AcceptableUsePolicy from './AcceptableUsePolicy';
import CancellationofAccounts from './CancellationofAccounts';
import ServicesareProvided from './ServicesareProvided';
import Liability from './Liability';
import Responsibles from './Responsible';
import Malicious from './Malicious';
import Property from './Property';
import Communication from './Communication';
import Miscellaneous from './Miscellaneous';

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          onClick={toggleDropdown}
          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
          aria-expanded={isOpen ? 'true' : 'false'}
          aria-haspopup="true"
        >
          Language
          <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fillRule="evenodd" d="M10 12l-8-8h16l-8 8z" clipRule="evenodd" />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <div className="py-1" role="none">
            <button onClick={() => changeLanguage('en')} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">English</button>
            <button onClick={() => changeLanguage('id')} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">Indonesian</button>
          </div>
        </div>
      )}
    </div>
  );
};

const Terms = () => {
  const { t } = useTranslation();

  return (
    <section className='py-5 px-5'>
      <div className='flex justify-center mt-16'>
        <h1 className='archivo-black-regular md:text-4xl text-2xl font-bold text-blue-500'>
          {t('Terms.header1')}
        </h1>
      </div>
      <div className='flex justify-between items-center ml-3 mr-3 md:ml-60 md:mr-60 font-lato text-lg'>
        <div></div> 
        <div>
          <LanguageSelector />
        </div>
      </div>
      <div className='text-left ml-3 mr-3 md:ml-60 md:mr-60 font-lato text-lg'>
        <p className='m-2'>{t('Terms.paragrap1')}</p>
        <p className='m-2'>{t('Terms.paragrap2')}</p>
        <p className='m-2'>{t('Terms.paragrap3')}</p>
        <p className='m-2'>{t('Terms.paragrap4')}</p>
        <p className='m-2'>{t('Terms.paragrap5')}</p>
        <p className='m-2'>{t('Terms.paragrap6')}</p>
        <p className='m-2'>{t('Terms.paragrap7')}</p>
        <p className='m-2'>{t('Terms.paragrap8')}</p>
      </div>

      <PrivacynPolicy />
      <Amendments />
      <AccountInformation />
      <FeesandTaxes />
      <AcceptableUsePolicy />
      <CancellationofAccounts />
      <ServicesareProvided />
      <Liability />
      <Responsibles />
      <Malicious />
      <Property />
      <Communication />
      <Miscellaneous />
    </section>
  );
};

export default Terms;
