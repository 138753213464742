import React from 'react';
import BAYARA from '../images/BAYARA.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <section id='footer' className='bg-gray-900'>
      <div className='justify-start text-left'>
        <img src={BAYARA} alt="LogoBayarA" className="h-12 w-auto ml-20" />
      </div>
      <div className='justify-start text-left md:flex md:justify-between md:text-center'>
        <div className='justify-start text-left'>
          <h2 className='text-5sm font-bold font-montserrat text-gray-300 ml-20 mt-20'>
            Home
          </h2>
          <ul className='text-gray-500 ml-20 mt-2'>
            <li>Unduh Aplikasi BayarA</li>
            <li><Link to='/privacy'>Kebijakan Privasi</Link></li>
            <li><Link to='/terms'>Syarat dan Ketentuan</Link></li>
          </ul>
        </div>
        <div className='justify-start text-left'>
          <h2 className='text-5sm font-bold font-montserrat text-gray-300 ml-20 mt-20 mr-20'>
            Tentang Kami
          </h2>
          <ul className='text-gray-500 ml-20 mt-2'>
            <li>Tentang BayarA</li>
            <li>BayarA</li>
            <li>Tim Kami</li>
          </ul>
        </div>
        <div className='justify-start text-left'>
          <h2 className='text-5sm font-bold font-montserrat text-gray-300 ml-20 mt-20 mr-20'>
            Support
          </h2>
          <ul className='text-gray-500 ml-20 mt-2'>
            <li>Bantuan</li>
          </ul>
        </div>
        <div className='justify-start text-left'>
          <h2 className='text-5sm font-bold font-montserrat text-gray-300 ml-20 mt-20 mr-20'>
            Office
          </h2>
          <p className='text-gray-500 ml-20 mt-2 mr-20'>
          Ruko Premier Park II Blok C-31
          </p>
          <p className='text-gray-500 ml-20 mt-2'>
                +62 822-80000-616
          </p>
          <p className='text-gray-500 ml-20 mt-2'>
                privacy@bayara.com
          </p>
        </div>
      </div>
      <div className='justify-center text-center mt-20'>
          <p className='text-sm text-gray-700'>© Copyright 2024 BayarA. All Rights Reserved.</p>
        </div>
    </section>
  );
};

export default Footer;
