import React, { useState, useEffect } from 'react';
import BAYARA from '../images/BAYARA.png';

const Navbar = ({ isTransparent, isResponsive }) => {
    const [open, setOpen] = useState(false);
    const [transparent, setTransparent] = useState(isTransparent);

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 0;
            setTransparent(isScrolled);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const toggleMenu = () => {
        setOpen(!open);
    };

    const handleSmoothScroll = (event, targetId) => {
        event.preventDefault();
        const targetElement = document.getElementById(targetId);
        targetElement.scrollIntoView({ behavior: 'smooth' });
        if (isResponsive) {
            setOpen(false);
        }
    };

    return (
        <div className={`p-2 w-full fixed top-0 left-0 transition duration-500 ease-in  bg-gray-900 md:${transparent ? 'bg-gray-400' : 'bg-transparent'}`} style={{ zIndex: '1000' }}>
            <div className="md:flex py-4 items-center justify-between md:px-10 px-7">
                <div className="cursor-pointer flex items-center">
                    <img src={BAYARA} alt="LogoBayarA" className="h-8 w-auto ml-6" />
                </div>
                <div onClick={toggleMenu} className="text-3xl absolute right-8 top-6 cursor-pointer md:hidden text-gray-400">
                    <ion-icon name={open ? 'close' : 'menu'}></ion-icon>
                </div>
                <ul className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition duration-500 ease-in ${open ? 'top-20 opacity-100' : 'top-[-490px]'} md:opacity-100 opacity-0 md:bg-transparent bg-gray-700`}>
                    <li className="md:ml-6 text-sm font-bold md:my-0 my-7">
                        <a href="#HomeSection" onClick={(e) => handleSmoothScroll(e, 'HomeSection')} className={`font-montserrat text-white md:text-white hover:text-gray-500 duration-${isResponsive ? '500' : '500'}`}>HOME</a>
                    </li>
                    <li className="md:ml-6 text-sm font-bold md:my-0 my-7">
                        <a href="#pricing" onClick={(e) => handleSmoothScroll(e, 'pricing')} className={`font-montserrat text-white md:text-white hover:text-gray-500 duration-${isResponsive ? '500' : '500'}`}>PRICING</a>
                    </li>
                    <li className="md:ml-6 text-sm font-bold md:my-0 my-7">
                        <a href="#features" onClick={(e) => handleSmoothScroll(e, 'features')} className={`font-montserrat text-white md:text-white hover:text-gray-500 duration-${isResponsive ? '500' : '500'}`}>FEATURES</a>
                    </li>
                    <li className="md:ml-6 text-sm font-bold md:my-0 my-7">
                        <a href="https://play.google.com/store/apps?hl=id&gl=US" className={`font-montserrat text-white md:text-white hover:text-gray-500 duration-${isResponsive ? '500' : '500'}`}>DOWNLOAD</a>
                    </li>
                    
                    <li className="md:ml-6 text-sm font-bold md:my-0 my-7">
                        <a href="#contact" onClick={(e) => handleSmoothScroll(e, 'contact')} className={`font-montserrat text-white md:text-white hover:text-gray-500 duration-${isResponsive ? '500' : '500'}`}>CONTACT</a>
                    </li>
                    <li className="md:ml-6 text-sm font-bold md:my-0 my-7">
                        <button className='bg-teal-600 hover:bg-gray-400 text-white font-bold py-2 px-4 rounded-full shadow-md transition duration-300 ease-in-out'>COBA DEMO</button>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Navbar;
