import React from 'react';
import NavTerm from '../layouts/NavTerm'
import Footer from '../layouts/Footer'
import gambartem from '../images/gambarterm.jpg'
import Terms from '../components/Terms'
 
const TermPage = () => {
  const containerStyle = {
    position: 'relative',
    zIndex: 0,
    width: '100%',
    minHeight: '100vh', 
  };

  const backgroundStyle = {
    position: 'absolute',
    zIndex: -1,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundImage: `url(${gambartem})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    filter: 'brightness(45%)', 

  };
  return (
    <>
      <NavTerm /> 
      <section id='termcondition' style={containerStyle}>
        <div style={backgroundStyle}></div>
        <div className='text-center'>
          <h1 className='archivo-black-regular font-bold text-white md:text-5xl text-2xl' style={{ marginTop: '20vh', transform: 'translateY(-50%)' }}>
            Terms & Conditions
          </h1>
        </div>
        
      </section>

      <Terms />

      <Footer />
    </>
  )
}; export default TermPage;
