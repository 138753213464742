import React from 'react'
import { useTranslation } from 'react-i18next'

const Liability = () => {
  const { t } = useTranslation();
  return (
    <section className='py-5 px-5'>
    <div className='justify-center text-center items-center mt-3 mb-8'>
        <h1 className='archivo-black-regular md:text-4xl text-2xl font-bold text-blue-500'>{t('Limit.header')}</h1>
    </div>
    <div className='text-left ml-3 mr-3 md:ml-60 md:mr-60 font-lato text-lg'>
        <p className='m-2'>{t('Limit.p1')}</p>
        <p className='mr-2 ml-2'>{t('Limit.p2')}</p>
        <p className='mr-2 ml-2'>{t('Limit.p3')}</p>
        <p className='mr-2 ml-2'>{t('Limit.p4')}</p>
        <p className='mr-2 ml-2'>{t('Limit.p5')}</p>
        <p className='mr-2 ml-2'>{t('Limit.p6')}</p>
        <p className='mr-2 ml-2'>{t('Limit.p7')}</p>
        <p className='mr-2 ml-2'>{t('Limit.p8')}</p>
        <p className='mr-2 ml-2'>{t('Limit.p9')}</p>
        <p className='mr-2 ml-2'>{t('Limit.p10')}</p>
        <p className='m-2'>{t('Limit.p11')}</p>
    </div>
    </section>
  )
}
export default Liability
