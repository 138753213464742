import React from 'react'
import { useTranslation } from 'react-i18next';

const AcceptableUsePolicy = () => {
  const { t } = useTranslation();

  return (
    <section className='mt-12'>
    <div className='justify-center text-center items-center mt-3 mb-8'>
        <h1 className='archivo-black-regular md:text-4xl text-2xl font-bold text-blue-500'>{t('Pol.header')}</h1>
    </div>
    <div className='text-left ml-3 mr-3 md:ml-60 md:mr-60 font-lato text-lg'>
        <p className='m-2'>{t('Pol.p1')}</p>
        <p className='mr-2 ml-2'>{t('Pol.p2')}</p>
        <p className='mr-2 ml-2'>{t('Pol.p3')}</p>
        <p className='mr-2 ml-2'>{t('Pol.p4')}</p>
        <p className='mr-2 ml-2'>{t('Pol.p5')}</p>
        <p className='mr-2 ml-2'>{t('Pol.p6')}</p>
        <p className='mr-2 ml-2'>{t('Pol.p7')}</p>
        <p className='mr-2 ml-2'>{t('Pol.p8')}</p>
        <p className='m-2'>{t('Pol.p9')}</p>
    </div>
    </section>
  )
}
export default AcceptableUsePolicy;